<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
    @click="emit('close')"
  >
    <div
      class="relative md:w-1/2 max-w-lg md:max-w-xl pt-6 bg-white rounded-md"
      @click.stop
    >
      <div class="px-6">
        <!-- Popup Content -->
        <h2 class="text-3xl my-5 font-semibold">Iniciar sesión</h2>
        <h3 class="text-xl my-3">
          Introduce tu email para acceder a tu perfil de socio.
        </h3>
        <UForm
          :schema="schema"
          :state="state"
          @submit="onSubmit"
          class="my-3 flex flex-col items-center justify-center gap-5"
        >
          <UFormGroup
            name="email"
            label="Email"
            class="w-full"
            variant="outline"
          >
            <CoreInputField
              additional-classes="!border-[1px] !border-dove-gray-300 ring-!transparent"
              variant="outline"
              v-model="state.email"
              placeholder="example@gmail.com"
            />
          </UFormGroup>
          <button
            :disabled="isSubmitting"
            class="rounded-sm py-2 px-3 w-fit my-5 disabled:cursor-not-allowed disabled:opacity-25 bg-brand-secondary text-white text-lg"
            type="submit"
          >
            Iniciar sesión
          </button>
        </UForm>
        <div class="py-6">
          <div
            v-if="showErrorMessage"
            class="text-xl text-center text-lavender-blush-700 lg:text-2xl 2xl:text-3xl"
          >
            {{ message }}
          </div>
        </div>
      </div>
      <button
        class="absolute top-4 right-4 size-5 text-gray-500 hover:text-gray-800"
        @click="emit('close')"
      >
        <nuxt-img src="images/close-gray.svg" class="size-5" />
      </button>
      <div class="py-2 bg-brand-gray/20 text-center w-full rounded-b-md">
        ¿Aún no estás registrado?
        <nuxt-link @click="emit('close')" to="/hazte-socio" class="underline"
          >Regístrate aquí.</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { object, string, type InferType } from "yup";
import type { FormSubmitEvent } from "#ui/types";
import { useReCaptcha } from "vue-recaptcha-v3";

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close"]);

const recaptchaInstance = useReCaptcha();

const message = ref("");
const schema = object({
  email: string()
    .email("El correo electrónico no es válido")
    .required("Este campo es obligatorio"),
});

type Schema = InferType<typeof schema>;
const state = reactive<Schema>({
  email: "",
});

const isSubmitting = ref(false);

const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
function resetForm() {
  Object.assign(state, {
    email: "",
  });
}
async function onSubmit(event: FormSubmitEvent<Schema>) {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  const isValid = await schema.isValid(state);

  try {
    if (isValid) {
      await recaptchaInstance?.recaptchaLoaded();
      const token = await recaptchaInstance?.executeRecaptcha("submit");

      if (token) {
        try {
          const response = await $fetch("/api/verify-recaptcha", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            params: {
              response: token,
            },
          });
          if (response.success) {
            const { data } = await useFetch("/api/auth", {
              method: "POST",
              body: {
                type: "request-login",
                data: {
                  login_url: useRequestURL().origin + "/request-login",
                  data: {
                    email: event.data.email,
                  },
                },
              },
            });

            if (data.value.success) {
              resetForm();
              emit("close");
              await navigateTo("/email-sent");
            } else {
              showSuccessMessage.value = false;
              showErrorMessage.value = true;
              message.value = data.value.message;
            }
          } else {
            showErrorMessage.value = true;
            throw new Error("reCAPTCHA verification failed.");
          }
        } catch (error) {
          console.log("error", error);
          showErrorMessage.value = true;
        }
      } else {
        showSuccessMessage.value = false;
        showErrorMessage.value = true;
        message.value = "Recaptcha error";
        console.log("Recaptcha error");
      }
    } else {
      console.error("Form validation failed");
      message.value = "Form validation failed";

      showSuccessMessage.value = false;
      showErrorMessage.value = true;
    }
  } catch (err) {
    // Handle unexpected errors
    console.error("Unexpected error:", err);

    showSuccessMessage.value = false;
    showErrorMessage.value = true;
    message.value = "An unexpected error occurred";
  } finally {
    isSubmitting.value = false;
  }
}
</script>
