<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useLoginPopUp } from "@/stores/login-popup-visibility";

const store = useLoginPopUp();

const { t } = useI18n();
const storeLink = t("store_link");
const emit = defineEmits(["menu"]);
const cookie = useCookie("authenticated") as any;

const isLoggedIn = ref(computed(() => (cookie.value ? true : false)));

async function logout() {
  cookie.value = null;
  emit("menu", false);
  reloadNuxtApp();
  await navigateTo("/");
}
function openLoginPopUp() {
  emit("menu", false);
  store.open();
}
</script>
<template>
  <div
    class="bg-brand-secondary text-white w-full h-svh z-20 flex text-center items-center flex-col justify-between"
  >
    <h2 @click="$emit('menu', false)" class="ml-auto mt-5 mr-5">
      <nuxt-img
        src="images/close-white.svg"
        class="w-5 float-right cursor-pointer"
      />
    </h2>
    <div v-if="!isLoggedIn" class="space-y-8">
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <nuxt-link @click="openLoginPopUp"> INICIA SESIÓN </nuxt-link>
      </div>
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <nuxt-link @click="$emit('menu', false)" to="/hazte-socio">
          HAZTE SOCIO
        </nuxt-link>
      </div>
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <nuxt-link @click="$emit('menu', false)" to="/vip-club">
          VIP CLUB
        </nuxt-link>
      </div>
    </div>
    <div v-else class="space-y-8">
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <nuxt-link @click="$emit('menu', false)" to="/profile">
          INICIO
        </nuxt-link>
      </div>
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <nuxt-link @click="$emit('menu', false)" to="/vip-club">
          VIP CLUB
        </nuxt-link>
      </div>
      <div
        class="px-[26px] py-3 bg-transparent border border-white text-white rounded-[36px] text-base leading-5 tracking-wide"
      >
        <button @click="logout">CERRAR SESIÓN</button>
      </div>
    </div>
    <div class="flex mb-9">
      <nuxt-link
        class="flex"
        @click="$emit('menu', false)"
        :to="storeLink"
        target="_blank"
      >
        <nuxt-img src="images/badges/shopping-cart-white.svg" class="w-5" />
        <span class="ml-2 font-bold mr-7">Store online</span>
      </nuxt-link>
    </div>
  </div>
</template>

<style></style>
