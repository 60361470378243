<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useLoginPopUp } from "@/stores/login-popup-visibility";

defineEmits(["menu"]);

const cookie = useCookie("authenticated") as any;

const isLoggedIn = ref(computed(() => (cookie.value ? true : false)));

async function logout() {
  cookie.value = null;
  await navigateTo("/");
  reloadNuxtApp();
}

const store = useLoginPopUp();
</script>
<template>
  <div class="flex justify-between items-center text-cerise-red-600">
    <div class="flex gap-3 items-center">
      <nuxt-link to="/">
        <nuxt-img
          src="images/logo.svg"
          alt="logo"
          class="h-16 md:h-20 w-auto"
        />
      </nuxt-link>
    </div>
    <div class="hidden lg:flex gap-7 items-center">
      <div class="flex gap-x-4">
        <button
          v-if="!isLoggedIn"
          class="px-[26px] py-3 bg-cerise-red-600 text-white rounded-3xl text-base !leading-[22px] tracking-wide"
          @click="store.open"
        >
          {{ t("buttons.login") }}
        </button>
        <nuxt-link
          v-if="!isLoggedIn"
          to="/hazte-socio"
          class="px-[26px] py-3 bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80% text-white rounded-3xl text-base !leading-[22px] tracking-wide"
          @click="null"
        >
          {{ t("buttons.memberRegistration") }}
        </nuxt-link>
      </div>
      <div v-if="isLoggedIn" class="flex gap-4">
        <nuxt-link
          to="/profile"
          class="px-[26px] py-3 bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80% text-white rounded-3xl text-base !leading-[22px] uppercase tracking-wide"
          >INICIO
        </nuxt-link>
        <button
          @click="logout()"
          class="px-[26px] py-3 bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80% text-white rounded-3xl text-base !leading-[22px] uppercase tracking-wide"
        >
          CERRAR SESIÓN
        </button>
      </div>
    </div>

    <div class="lg:hidden block">
      <div
        class="bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80% w-12 h-12 rounded-full items-center flex justify-center"
      >
        <nuxt-img
          @click="$emit('menu', true)"
          src="images/hamburger.svg"
          alt="Menu"
          format="webp"
          class="bg-transparent cursor-pointer w-1/2"
        />
      </div>
    </div>
  </div>
</template>
